import axios from "axios";
import { baseUrl } from './baseUrl';

// 定义项目基础路径
// 开发时使用的本地代理路径
// var baseUrl = "http://h.ceecexpo.com/api/v1" ; // 打包发布时使用的真实接口路径

// 封装一个通用的get请求函数
function ajax_get(url, params = {}, responseType = "", headers) {
    return new Promise(function (resovle) {
        axios.get(url, { params }, responseType, headers).then(res => {
            resovle(res.data)
        })
    })
};
// 封装一个通用的post请求函数
function ajax_post(url, params = {}, headers) {
    return new Promise(function (resovle) {
        axios.post(url, params, headers).then(res => {
            resovle(res.data)
        })
    })
};

// 登录 
function postlogin(header, params) {
    return ajax_post(baseUrl + '/member/login', params, header)
};
// 用户注册接口 
function postregister(params, headers) {
    return ajax_post(baseUrl + '/member/register', params, headers)
};
// 发送验证码接口 /member/sedSms
function postSedSms(params, headers) {
    return ajax_post(baseUrl + '/member/sedSms', params, headers)
};
// /member/restPwd 重置密码
function postrestPwd(params, headers) {
    return ajax_post(baseUrl + '/member/restPwd', params, headers)
}



function cityList(params, headers) {
    return ajax_get(baseUrl + '/basic/cityList', params, headers)
};
/* 
        首页
*/
//  首页出发站
function homecityList(params, headers) {
    return ajax_get(baseUrl + '/basic/cityList', params, headers)
};

//   / 首页租箱推荐接口
function recommend(headers) {
    return ajax_get(baseUrl + '/box/recommend', headers)
};
// 租箱列表 rentBox  现货
function rentBoxList(params, headers) {
    return ajax_get(baseUrl + '/box/list', params, headers)
}
// /box/list 预售 presale
function presaleList(params, headers) {
    return ajax_get(baseUrl + '/box/list', params, headers)
}
// 租箱下单 /boxOrder/addOrder
function postAddOrder(params, headers) {
    return ajax_post(baseUrl + '/boxOrder/addOrder', params, headers)
}
//  新闻
function newsList(responseType, headers) {
    return ajax_get(baseUrl + '/basic/newsList?pageNo=1', responseType, headers)
};

//  新闻详情
function newsDetail(params, headers) {
    return ajax_get(baseUrl + '/basic/newsDetail', params, headers)
}

// about 关于我们
function about(headers) {
    return ajax_get(baseUrl + '/basic/about', headers)
};

/* 
    询柜
*/
// 港口选择接口
function getseaList(headers) {
    return ajax_get(baseUrl + '/basic/seaList', headers)
}
// /inquiry/addInquiry
function postAddInquiry(headers, params) {
    return ajax_post(baseUrl + '/inquiry/addInquiry', params, headers)
}

// 起运港
function startSeaList(params) {
    return ajax_get(baseUrl + '/basic/startSeaList', params)
}
// 目的港
function endSeaList(params) {
    return ajax_get(baseUrl + '/basic/endSeaList', params)
}
// 起运站
function startCityList(params) {
    return ajax_get(baseUrl + '/basic/startCityList', params)
}
// 目的站
function endCityList(params) {
    return ajax_get(baseUrl + '/basic/endCityList', params)
}

export {
    postlogin, //用户登录
    postSedSms,  //验证码
    postregister, //用户注册
    postrestPwd,  //重置密码
    cityList,   //地区
    homecityList, //首页出发站
    recommend,  //首页特价线路接口  / 首页租箱推荐接口
    rentBoxList, //租箱列表 现货
    presaleList, //租箱列表 预售
    postAddOrder,//租箱下单
    about,
    newsList, //新闻列表
    newsDetail, //新闻详情

    getseaList, //港口选择接口
    postAddInquiry, //发起询价接口

    startSeaList, //起运港
    endSeaList,//目的港
    startCityList,//起运站
    endCityList, //目的站
}