<template>
    <div class="banner">
        <!-- <el-carousel :interval="5000" arrow="always" indicator-position="none" height="600px"> -->
        <!-- <el-carousel-item v-for="item in 4" :key="item"> -->
        <img class="banner_Img" src="../assets/homePage/banner2.png" alt="">
        <div class="banner-content">
            <div class="banner-contents banner-content-1" :class="handleFlag?'active':''" @click="handleRentBox">
                立即租箱
            </div>
            <div class="banner-contents banner-content-2" :class="!handleFlag?'active':''" @click="handleBookingSpace">
                我要订舱</div>
        </div>
        <!-- </el-carousel-item> -->
        <!-- </el-carousel> -->
    </div>
</template>

<script>
export default {
    name: "Banner",
    data() {
        return {
            handleFlag: true,
        }
    },
    methods: {
        handleRentBox() {

            this.$router.push('/RentalBoxOnHand');
            this.handleFlag = true;
        },
        handleBookingSpace() {

            this.$router.push('/EnquiryCabinet/OceanShipping');
            this.handleFlag = false;
        }
    },

}
</script>

<style lang="scss" scoped>
.active {

    background: #1890FF !important;
}

.banner {
    position: relative;
    width: 100%;
    // z-index: 1;
    height: 600px;
    margin: 0 auto;
    // border: 1px solid;

    .banner_Img {
        width: 100%;
        height: 100%;
    }

    .banner-content {
        position: absolute;
        left: 250px;
        bottom: 174px;
        z-index: 10;
        display: flex;
        // width: 100px;
        height: 50px;
        font-size: 20px;
        word-spacing: normal;

        .banner-contents {
            width: 200px;
            font-weight: 400;
            color: #333333;
            line-height: 50px;
            text-align: center;
            border-radius: 2px 2px 2px 2px;
            background-color: #FFFFFF;
            cursor: pointer;
        }

        .banner-content-2 {
            margin-left: 60px;

        }
    }
}
</style>