import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    loginloginWindow: false,
    phone: "",
    authInfo: {},//认证信息
    subAccount: "",
  },
  mutations: {
    set_token(state, token) {
      state.token = token;
      // sessionStorage.token = token;
      // sessionStorage.setItem("token", token);
      sessionStorage.setItem("token", token);
      localStorage.setItem("token", token)
      // console.log(state.token);
    },
    set_phone(state, value) {
      state.phone = value;
      localStorage.setItem("phone", value);
    },
    set_authInfo(state, value) { //认证信息
      state.authInfo = value;
      localStorage.setItem("authInfo", JSON.stringify(value));
    },
    loginloginWindow(state, value) {
      state.loginloginWindow = value
    },
    set_subAccount(state, value) {
      state.subAccount = value;
      localStorage.setItem("subAccount", value);
    }
  },
  actions: {
  },
  modules: {
  }
})
