import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
});

import { Radio, Upload, Input, Carousel, CarouselItem, Dropdown, Button, DropdownMenu, DropdownItem, Form, FormItem, Select, Option, InputNumber, DatePicker, Table, TableColumn, Pagination, Step, Steps, Container, Aside, Main, Popover, Tag, TimeSelect, MenuItemGroup, Submenu, Menu, MenuItem, Row, Col , Dialog} from 'element-ui'
import { Message } from 'element-ui';
Vue.use(Input);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Dropdown);
Vue.use(Button);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Container);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Popover);
Vue.use(Tag);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(TimeSelect);
Vue.use(MenuItemGroup);
Vue.use(Submenu);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dialog);

Vue.prototype.$message = Message;

Vue.component(Message.name, Message);//解决默认弹出Message 提示框

Vue.config.productionTip = false;
// import axios from 'axios'
import ExTableColumn from 'ex-table-column';
Vue.component(ExTableColumn.name, ExTableColumn);

Vue.directive(
  'el-select-loadmore', {
  bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      /**
      * scrollHeight 获取元素内容高度(只读)
      * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
      * clientHeight 读取元素的可见高度(只读)
      * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
      * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
      */
      const heights = Math.floor(this.scrollHeight - this.scrollTop)
      const condition = heights <= this.clientHeight;
      if (condition) {
        binding.value();
      }
    });
  }
}
)
import axios from "axios";



// 动态修改浏览器导航栏图标和文字
export function changeIconTitle() {
  const changeFavicon = link => {
    let $favicon = document.querySelector('link[rel="icon"]');
    if ($favicon !== null) {
      $favicon.href = link;
    } else {
      $favicon = document.createElement("link");
      $favicon.rel = "icon";
      $favicon.href = link;
      document.head.appendChild($favicon);
    }
  };
  let iconUrl = ""; // 图片地址
  changeFavicon(iconUrl); // 动态修改网站图标
  let title = "侨链国际"; // 网站标题
  document.title = title; // 动态修改网站标题
}

Vue.filter('getCurrency', function (currencyValue) {
  if (currencyValue == 'USD') {
    return '$'
  } else if (currencyValue == 'EUR') {
    return '€'
  } else if (currencyValue == 'CNY') {
    return '¥'
  }
})
import './assets/font/font.css';
import { getMoney } from './tools/select';
Vue.prototype.select = getMoney

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
