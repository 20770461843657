<template>
  <div class="ServerComponent">
    <div class="ServerContent">
      <!--快捷订箱  -->
      <div class="expressBook">
        <img src="../assets/homePage/lightning.png" alt="">
        <span>快捷订箱</span>
      </div>
      <!-- 搜索 -->
      <div class="server">
        <div class="selectS">
          <el-select v-model="departureStationValue" placeholder="出发站" v-el-select-loadmore="loadmore" filterable
            :filter-method="remoteMethod" @focus="changeFous">
            <el-option v-for="(item, index) in departureStationList" :key="index" :label="item.city" :value="item.city">
            </el-option>
          </el-select>
        </div>
        <div class="selectS">
          <el-select v-model="destinationValue" placeholder="目的地" v-el-select-loadmore="loadmore" filterable
            :filter-method="remoteMethod1" @focus="changeFous">
            <el-option v-for="item in destinationListList" :key="item.city_en" :label="item.city" :value="item.city">
            </el-option>
          </el-select>
        </div>
        <div class="selectS">
          <el-select v-model="selectBoxTypeValue" placeholder="选择箱型">
            <el-option v-for="item in selectBoxType" :key="item.label" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <el-button class="server_Button" @click="handleServe">搜索</el-button>
      </div>

      <!-- Banner -->
      <div class="server_Banner">
        <el-carousel height="246px" indicator-position="none">
          <el-carousel-item v-for="item in bannerList" :key="item">
            <img :src="item" alt="">
          </el-carousel-item>
        </el-carousel>
        <!-- <img src="../assets/homePage/banner.png" alt=""> -->
      </div>
    </div>

    <!-- 特价线路 -->

    <SpecialLine_Component></SpecialLine_Component>

  </div>
</template>

<script>
import SpecialLine_Component from './SpecialLine_Component.vue'
import { homecityList } from '../tools/ajax'
import { baseUrl } from '.././tools/baseUrl';
import axios from 'axios'

export default {
  name: "specialLine_Component",
  components: { SpecialLine_Component },
  // directives: {
  //   'el-select-loadmore': {
  //     bind(el, binding) {
  //       // 获取element-ui定义好的scroll盒子
  //       const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
  //       SELECTWRAP_DOM.addEventListener('scroll', function () {
  //         /**
  //         * scrollHeight 获取元素内容高度(只读)
  //         * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
  //         * clientHeight 读取元素的可见高度(只读)
  //         * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
  //         * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
  //         */
  //         const heights = Math.floor(this.scrollHeight - this.scrollTop)
  //         const condition = heights <= this.clientHeight;
  //         if (condition) {
  //           binding.value();
  //         }
  //       });
  //     }
  //   }
  // },
  data() {
    return {
      bannerList: [
        require("../assets/ship/banner.png"),
        require("../assets/ship/banner1.png"),
        require("../assets/ship/banner3.png"),
      ],
      departureStationValue: "",  //出发站值
      departureStationList: [],  //出发站数据
      destinationListList: [],  //目的站数据
      destinationValue: "", //目的站值
      selectBoxTypeValue: "", //选择箱型值
      selectBoxType: [{  //选择箱型
        value: '20GP',
        label: '20GP'
      }, {
        value: '40GP',
        label: '40GP'
      }, {
        value: '40HQ',
        label: '40HQ'
      }, {
        value: '45HQ',
        label: '45HQ'
      }, {
        value: '20OT',
        label: '20OT'
      },
      {
        value: '40OT',
        label: '40OT'
      },
      {
        value: '20NOR',
        label: '20NOR'
      }, {
        value: '40NOR',
        label: '40NOR'
      },
      {
        value: '20FR',
        label: '20FR'
      },
      {
        value: '40FR',
        label: '40FR'
      },
      {
        value: '20ISO',
        label: '20ISO'
      },],
      formData: {
        pageNo: 1, //当前页
      },
    }
  },
  created() { //快捷订箱请求
    homecityList({ pageNo: '1' }).then((res) => {
      this.departureStationList = res.result.records;
      this.destinationListList = res.result.records;
    })
  },

  methods: {
    changeFous() {
      this.formData.pageNo = 1;
      homecityList().then(res => {
        // console.log(res.result);
        this.departureStationList = res.result.records;
        this.destinationListList = res.result.records;
      });
    },
    remoteMethod(value) { //
      this.departureStationValue = value;
      // console.log("$$$", value);
      if (value) {
        axios.get(`${baseUrl}/basic/cityList`, {
          params: { name: value }
        })
          .then(res => {
            // console.log("---", res.data.result.records);
            this.departureStationList = res.data.result.records;
            // this.destinationListList = res.data.result.records;
          })
          .catch(err => {
            console.error(err);
          })
      } else {
        homecityList().then(res => {
          // console.log(res.result);
          this.departureStationList = res.result.records;
          // this.destinationListList = res.result.records;
        });
      }

    },
    remoteMethod1(value) {
      this.destinationValue = value;
      if (value) {
        axios.get(`${baseUrl}/basic/cityList`, {
          params: { name: value }
        })
          .then(res => {
            // console.log("---", res.data.result.records);
            // this.departureStationList = res.data.result.records;
            this.destinationListList = res.data.result.records;
          })
          .catch(err => {
            console.error(err);
          })
      } else {
        homecityList().then(res => {
          // console.log(res.result);
          // this.departureStationList = res.result.records;
          this.destinationListList = res.result.records
        });
      }
    },



    handleServe() {  //快捷订箱 搜索按钮
      // start_city_id=16&end_city_id=20&box_type_id=1
      this.$router.push({
        name: "RentalBoxOnHand",
        query: {
          start_city: this.departureStationValue,
          end_city: this.destinationValue,
          box_type: this.selectBoxTypeValue
        }
      })
    },
    // #region //搜索框
    disableVisible(e) { //输入搜索
      // console.log("^^", e.target.value);
      homecityList({ name: e.target.value }).then((res) => {
        this.departureStationList = res.result.records;
        this.destinationListList = res.result.records;
      })
    },
    changeServer(e) { //输入搜索
      homecityList({ name: e.target.value }).then((res) => {

        this.departureStationList = res.result.records;
        this.destinationListList = res.result.records;
        // console.log(res.result.records);
      })
      // console.log("$$$", e.target.value);
    },
    // 懒加载
    loadmore() {
      this.formData.pageNo++;
      this.getList(this.formData);
    },
    // 下拉刷新
    getList(formData) {
      homecityList({ pageNo: formData.pageNo }).then((res) => {
        const _res = res.result.records;
        this.departureStationList = [...this.departureStationList, ..._res];
        this.destinationListList = [...this.destinationListList, ..._res];
      })
    }
    // #endregion
  },

}
</script>

<style lang="scss" scoped>
::v-deep.el-popper {
  // overflow-y: scroll;
  width: 284px;
  max-height: 255px;
}



::v-deep .el-icon-arrow-up:before {
  content: "\e78f";
}

.el-dropdown-menu__item {
  // width: 142px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
  // background: rgba(153,153,153,0.2)
}

.el-dropdown-menu__item:hover {
  background: rgba(153, 153, 153, 0.2);
  color: #333333;
  // box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.16);
}

// 滚动条样式
::-webkit-scrollbar {
  width: 4px;
  height: 48px;
  border-radius: 2px 2px 2px 2px;
}

::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
  transition: all 2s;
}


.ServerComponent {
  width: 1200px;
  margin: 56px auto 0px;

  .ServerContent {
    width: 1200px;
    margin: 0 auto;

    .expressBook {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 224px;
      height: 60px;
      border-radius: 5px;
      // background: #1890FF;
      // border-radius: 0px 60px 0px 0px;
      background: url('../assets/homePage/searchTop.png') no-repeat;
      background-size: cover;

      img {
        margin-left: 64px;
        width: 14px;
        height: 18px;
        margin-right: 9px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
      }
    }

    .server {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 1200px;
      height: 94px;
      background: #FFFFFF;
      border-radius: 0px 16px 16px 16px;
      border: 1px solid #999999;

      .selectS {
        width: 284px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .el-select {
          width: 100%;
          height: 100%;

        }
      }

      .server_Button {
        width: 120px;
        height: 40px;
        background: #FFC13B;
        box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
        border-radius: 4px 4px 4px 4px;
        border: none;
      }

      .el-dropdown {
        width: 284px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        // border: 1px solid #999999;

        .el-button {
          position: relative;
          display: flex;
          // justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 14px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 12px;
          border-color: #999999;

          .el-icon-arrow-down {
            position: absolute;
            right: 8px;
          }

        }
      }


    }
  }

  .server_Banner {
    width: 1200px;
    height: 246px;
    // border: 1px solid;
    margin-top: 54px;
    border-radius: 16px 16px 16px 16px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>