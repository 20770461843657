var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer_Center"},[_c('div',{staticClass:"footer_Content"},[_vm._m(0),_c('div',{staticClass:"footer_Content_Center-box"},[_c('div',{staticClass:"footer_ContentS footer_Content_Center"},[_c('div',[_c('ul',[_vm._m(1),_c('li',{on:{"click":_vm.handleRentBox}},[_c('span',[_vm._v("租箱现货")])]),_c('li',{on:{"click":_vm.handlePresale}},[_c('span',[_vm._v("租箱预售")])]),_c('li',{on:{"click":_vm.handleOceanShipping}},[_c('span',[_vm._v("海运询价")])]),_c('li',{on:{"click":_vm.handleLandTransportation}},[_c('span',[_vm._v("陆运询价")])]),_c('li',{on:{"click":_vm.handleAirTransport}},[_c('span',[_vm._v("空运询价")])])])]),_c('div',{staticClass:"showFooter-box"},[_c('span',{staticClass:"span-txt1",on:{"mouseover":_vm.showMouseOver1,"mouseleave":_vm.showMouseLeave1}},[_vm._v("全球散货拼箱")]),_c('br'),_c('span',{on:{"mouseover":_vm.showMouseOver2,"mouseleave":_vm.showMouseLeave2}},[_vm._v("整箱服务")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCard),expression:"showCard"}],staticClass:"show-card-box",on:{"mouseover":_vm.showMouseOver1,"mouseleave":_vm.showMouseLeave1}},[_vm._m(2),_c('div',{staticClass:"triangle"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCard2),expression:"showCard2"}],staticClass:"show-card-box2",on:{"mouseover":_vm.showMouseOver2,"mouseleave":_vm.showMouseLeave2}},[_vm._m(3),_c('div',{staticClass:"triangle"})])]),_vm._m(4)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_Content_Left footer_ContentS"},[_c('ul',[_c('li',[_c('p',[_vm._v("联系我们")])]),_c('li',[_c('span',[_vm._v("电话：400-875-2228")])]),_c('li',[_c('span',[_vm._v("邮编：315000")])]),_c('li',[_c('span',[_vm._v("工作时间：9：00-17：00")])]),_c('li',[_c('span',[_vm._v("E-mail：support@ceecexpo.com")])]),_c('li',[_c('span',[_vm._v("地址：浙江省宁波市鄞州区会展中心10号馆")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("服务范围")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-box"},[_c('p',{staticClass:"txt-table"},[_vm._v("林朝宏 ")]),_c('span',{staticClass:"txt-poren"},[_vm._v("15869591741")]),_c('span',{staticClass:"txt-copy"},[_vm._v("link@ceecexpo.com")]),_c('span',{staticClass:"txt-address"},[_vm._v("浙江省宁波市国际会展中心10号馆B5009")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-box"},[_c('p',{staticClass:"txt-table"},[_vm._v("林朝宏 ")]),_c('span',{staticClass:"txt-poren"},[_vm._v("15869591741")]),_c('span',{staticClass:"txt-copy"},[_vm._v("link@ceecexpo.com")]),_c('span',{staticClass:"txt-address"},[_vm._v("浙江省宁波市国际会展中心10号馆B5009")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_Content_Right footer_ContentS"},[_c('div',{staticClass:"followUs"},[_c('img',{attrs:{"src":require("../assets/homePage/0db765074d7a3ba1832425684a9ac18.png"),"alt":""}})]),_c('div',{staticClass:"followUsS"},[_c('span',[_vm._v("关注我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_Bottom"},[_c('p',[_vm._v("Copyright 2022 侨链国际 版权所有    浙ICP备2020044037号-1 浙公网安备33021202001524号")])])
}]

export { render, staticRenderFns }