<template>
    <div>
        <div class="aboutUs">
            <img class="aboutUs_Img" src="../assets/homePage/548.png" alt="">
            <div class="aboutUs_Left">
                <div class="aboutUs_Title">
                    <p>关于我们</p>
                </div>
                <div class="aboutUs_Content">
                    <span>宁波侨链国际供应链管理有限公司位于宁波国际会展中心，在成立之初就确立了自己的使命，那就是促进全球通商，成就创业梦想。为国内外消费者提供不加价的进出口商品，打造商品核心竞争力。为无数的创业者搭建一个平台，让每个人、不论业务规模大小，都可以通过在线交易平台开展他们自己的业务，实现他们的创业梦想。</span>
                </div>
            </div>
            <div class="aboutUs_Right"></div>

        </div>
        <!-- 新闻中心 -->
        <div class="newsCenter">
            <div class="newCenterBox">
                <div class="newsCenter_Title">
                    <span>新闻中心</span>
                </div>

                <div class="newsCenter_Content">
                    <ul>
                        <li v-for="(item, index) in newsWenList" :key="index" @click="handleNewWenDetail(item.id)"
                            v-show="index < 3">
                            <div class="newsCenter_Content_Title">
                                <span>{{ item.title }}</span>
                            </div>
                            <div class="newsCenter_Content_DateOf"><span>{{ item.pushTime.split(' ')[0] }}</span></div>
                            <div class="newsCenter_ContentS">
                                <span>{{ item.content }}</span>
                            </div>
                            <div class="newsCenter_Img"><img :src="'https://tu.ceecexpo.com/upload/' + item.images"
                                    alt="" /></div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="seeMore" @click="handleSeeMore">
            <span>查看更多</span>
        </div>
    </div>
    <!-- RentBox -->
</template>

<script>
import { newsList } from '../tools/ajax'
export default {
    data() {
        return {
            newsWenList: [],//新闻列表
        }
    },
    async created() {

        await newsList().then(res => {
            this.newsWenList = res.result.records;
            // console.log(this.newsWenList);
            let reg = new RegExp('<[^<>]+>', 'g')
            var textList = this.newsWenList;
            textList.forEach((v, i) => {
                this.newsWenList[i].content = v.content.replace(reg, "");
                this.newsWenList[i].content = this.newsWenList[i].content.replace(/&ldquo;/ig, "");
                this.newsWenList[i].content = this.newsWenList[i].content.replace(/&middot;/ig, "");
                this.newsWenList[i].content = this.newsWenList[i].content.replace(/&rdquo;/ig, "");
            })
        });
    },

    methods: {
        handleNewWenDetail(ids) {
            // console.log(ids);
            this.$router.push({
                path: "/NewsCenter",
            })
        },
        handleSeeMore() {
            this.$router.push({
                path: "/NewsCenter",
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.aboutUs {
    position: relative;
    width: 100%;
    height: 595px;
    margin-top: 54px;

    .aboutUs_Img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .aboutUs_Left {
        position: relative;
        width: 50%;

        .aboutUs_Title {
            box-sizing: border-box;
            position: absolute;
            right: 0;
            width: 600px;
            height: 160px;
            background: #1890FF;
            padding-left: 80px;

            p {
                position: relative;
                font-size: 40px;
                font-family: '黑体';
                font-weight: bold;
                color: #FFFFFF;
                line-height: 80px;
            }

            p::before {
                content: '';
                position: absolute;
                bottom: 0px;
                width: 100px;
                height: 4px;
                background: #FFFFFF;
            }
        }

        .aboutUs_Content {
            position: absolute;
            right: 0;
            top: 240px;
            width: 600px;
            height: 190px;
            font-size: 18px;
            font-family: 'Alibaba PuHuiTi45';
            // font-family: '宋体';
            // font-weight: normal;
            color: #FFFFFF;
            line-height: 32px;
        }


    }

    .aboutUs_Right {
        width: 50%;
    }
}

.newsCenter {
    // width: 1200px;
    width: 100%;
    // margin: 45px auto 0;
    background-color: #f0f2f5;

    .newCenterBox {
        width: 1200px;
        // margin: 45px auto 0;
        padding-top: 45px;
        padding-bottom: 50px;
        margin: 0 auto;

    }

    .newsCenter_Title {
        // box-sizing: border-box;
        height: 30px;
        font-size: 30px;
        font-family: '黑体';
        font-weight: 400;
        color: #2B2B2B;
        line-height: 24px;
        padding-bottom: 32px;
        border-bottom: 1px solid #999999;


        span {
            padding-bottom: 6px;
            border-bottom: 1px solid #FFC13B !important;
        }
    }

    .newsCenter_Content {
        box-sizing: border-box;
        margin-top: 24px;

        ul {
            width: 100%;
            display: flex;
            justify-content: space-around;

            li {
                position: relative;
                box-sizing: border-box;
                width: 386px;
                // height: 398px;
                // border: 1px solid #999999;
                background: #ffffff;
                border-radius: 4px 4px 4px 4px;
                padding: 24px 24px 22px 24px;

                .newsCenter_Content_Title {
                    position: relative;
                    // height: 24px;
                    font-size: 24px;
                    font-family: '黑体';
                    font-weight: bold;
                    color: #1890FF;
                    line-height: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;


                }

                .newsCenter_Content_DateOf {
                    height: 14px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 14px;
                    // padding-left: 12px;
                    margin-top: 6px;

                    span {
                        height: 100%;
                    }
                }

                .newsCenter_ContentS {
                    margin-top: 16px;


                    span {
                        font-size: 18px;
                        font-weight: 400;
                        color: #333333;

                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                .newsCenter_Img {
                    // border: 1px solid;
                    width: 338px;
                    height: 226px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    margin-top: 17px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            li::before {
                content: "";
                position: absolute;
                left: 0px;
                top: 36px;
                transform: translate(0, -50%);
                width: 4px;
                height: 24px;
                background: #1890FF;
                // background-color: red;
            }

            li:hover {
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
            }
        }
    }
}

.seeMore {
    width: 490px;
    height: 60px;
    background: #1890FF;
    border-radius: 2px 2px 2px 2px;
    margin: 54px auto 52px;
    text-align: center;
    font-size: 24px;
    line-height: 60px;
    color: #FFFFFF;
    cursor: pointer;
}
</style>