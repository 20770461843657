
<template>
    <div class="footer">

        <div class="footer_Center">
            <div class="footer_Content">
                <div class="footer_Content_Left footer_ContentS">
                    <ul>
                        <li>
                            <p>联系我们</p>
                        </li>
                        <li><span>电话：400-875-2228</span></li>
                        <li><span>邮编：315000</span></li>
                        <li><span>工作时间：9：00-17：00</span></li>
                        <li><span>E-mail：support@ceecexpo.com</span></li>
                        <li><span>地址：浙江省宁波市鄞州区会展中心10号馆</span></li>
                    </ul>
                </div>
                <div class="footer_Content_Center-box">
                    <div class=" footer_ContentS footer_Content_Center">
                    <div>
                        <ul>
                            <li>
                                <p>服务范围</p>
                            </li>
                            <li @click="handleRentBox"><span>租箱现货</span></li>
                            <li @click="handlePresale"><span>租箱预售</span></li>
                            <li @click="handleOceanShipping"><span>海运询价</span></li>
                            <li @click="handleLandTransportation"><span>陆运询价</span></li>
                            <li @click="handleAirTransport"><span>空运询价</span></li>
                        </ul>
                    </div>

                    <div class="showFooter-box">
                        <span class="span-txt1" @mouseover="showMouseOver1" @mouseleave="showMouseLeave1">全球散货拼箱</span><br>
                        <span @mouseover="showMouseOver2" @mouseleave="showMouseLeave2">整箱服务</span>
                    </div>
                </div>
                <div class="show-card-box" v-show="showCard" @mouseover="showMouseOver1" @mouseleave="showMouseLeave1">
                        <div class="show-box">
                            <p class="txt-table">林朝宏 </p>
                            <span class="txt-poren">15869591741</span>
                            <span class="txt-copy">link@ceecexpo.com</span>
                            <span class="txt-address">浙江省宁波市国际会展中心10号馆B5009</span>
                        </div>
                        <div class="triangle"></div>
                    </div>

                    <div class="show-card-box2" v-show="showCard2" @mouseover="showMouseOver2" @mouseleave="showMouseLeave2">
                        <div class="show-box">
                            <p class="txt-table">林朝宏 </p>
                            <span class="txt-poren">15869591741</span>
                            <span class="txt-copy">link@ceecexpo.com</span>
                            <span class="txt-address">浙江省宁波市国际会展中心10号馆B5009</span>
                        </div>
                        <div class="triangle"></div>
                    </div>
                </div>
               

                

                <div class="footer_Content_Right footer_ContentS">
                    <!-- 二维码 -->
                    <div class="followUs">
                        <img src="../assets/homePage/0db765074d7a3ba1832425684a9ac18.png" alt="">
                    </div>
                    <div class="followUsS"><span>关注我们</span></div>
                </div>

            </div>
        </div>
        <div class="footer_Bottom">
            <p>Copyright 2022 侨链国际 版权所有&emsp;&emsp;&emsp;&emsp;浙ICP备2020044037号-1 浙公网安备33021202001524号</p>
        </div>


    </div>
</template>

<script>
export default {
    name: "Footer_Compontent",
    data() {
        return {
            showCard: false,
            showCard2:false
        }
    },

    methods: {
        handleRentBox() {  //跳转租箱现货
            this.$router.push("/RentalBoxOnHand")
        },
        handlePresale() { //跳转租箱预售
            this.$router.push("/BoxRentalAndPre_Sale")
        },
        handleOceanShipping() { //跳转海运询柜
            this.$router.push("/EnquiryCabinet/OceanShipping")
        },
        handleLandTransportation() { //跳转陆运询柜
            this.$router.push("/EnquiryCabinet/Railway")
        },
        handleAirTransport() { //跳转空运询柜
            this.$router.push("/EnquiryCabinet/AirTransport")
        },
        showMouseOver1() {
            this.showCard = true
        },
        showMouseLeave1() {
            this.showCard = false
        },
        showMouseOver2() {
            this.showCard2 = true
        },
        showMouseLeave2() {
            this.showCard2 = false
        }
    },
}
</script>

<style lang="scss" scoped>
.footer {
    width: 100%;
    z-index: 100;

    .footrt_Partner {
        width: 100%;
        height: 440px;
        background: #313542;
        text-align: center;
        overflow: hidden;


        .footer_Title {
            width: 100%;
            margin-top: 62px;
            font-size: 30px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;

        }
    }

    .footer_Center {
        width: 100%;
        height: 360px;
        background: #292C35;


        .footer_Content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 1200px;
            // height: 100%;
            margin: 0 auto;

            .footer_ContentS {
                margin-top: 48px;
                // border: 1px solid red;

                p {
                    font-weight: bold;
                    font-family: '黑体';
                    color: #FFFFFF;
                    line-height: 55px;
                    font-size: 24px;
                }

                ul {
                    li {
                        margin-top: 16px;
                        cursor: pointer;
                    }

                    li:nth-child(1) {
                        margin-top: 0;
                    }

                    li:nth-child(2) {
                        margin-top: 44px;
                    }
                }

                span {
                    display: inline-block;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 18px;
                    font-size: 18px;

                }

                .followUs {
                    width: 140px;
                    height: 140px;
                    // border: 1px solid;
                    margin-top: 50px;
                }

                .followUsS {
                    text-align: center;
                    margin-top: 18px;
                }
            }

        }
    }

    .footer_Bottom {
        width: 100%;
        height: 80px;
        text-align-last: center;
        background: #1F2228;
        line-height: 80px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 55px;

        p {
            color: #FFFFFF;
        }
    }
}

.footer_Content_Center-box{
    width: 500px;
    height:300px;
    // border: 1px solid red;
    position: relative;
    .show-card-box {
    width: 250px;
    height: 180px;
    position: absolute;
    right: 120px;
    top: -30px;
    // border: 1px solid red;

    .triangle {
        width: 0;
        height: 0;
        border: 10px solid;
        margin-left: 35px;
        border-color: #fff transparent transparent transparent;
    }

    .show-box {
        width: 250px;
        height: 160px;
        background: #fff;
        background-image: url("../assets/ship/card.png");
        background-position: center center;
        background-size: 100% 100%;
        border-radius: 3px;
        position: relative;

        .txt-table {
            position: absolute;
            top: 30px;
            left: 70px;
            color: #292C35;
            font-size: 18px;
            font-weight: bold;
        }

        .txt-poren {
            position: absolute;
            top: 70px;
            left: 90px;
            color: #292C35;
            font-size: 14px;
        }

        .txt-copy {
            position: absolute;
            top: 95px;
            left: 90px;
            color: #292C35;
            font-size: 14px;
        }

        .txt-address {
            width: 150px;
            position: absolute;
            top: 120px;
            left: 90px;
            color: #292C35;
            font-size: 12px;
        }
    }
}
.show-card-box2 {
    width: 250px;
    height: 180px;
    position: absolute;
    right: 120px;
    top: 5px;
    // border: 1px solid red;

    .triangle {
        width: 0;
        height: 0;
        border: 10px solid;
        margin-left: 35px;
        border-color: #fff transparent transparent transparent;
    }

    .show-box {
        width: 250px;
        height: 160px;
        background: #fff;
        background-image: url("../assets/ship/card.png");
        background-position: center center;
        background-size: 100% 100%;
        border-radius: 3px;
        position: relative;

        .txt-table {
            position: absolute;
            top: 30px;
            left: 70px;
            color: #292C35;
            font-size: 18px;
            font-weight: bold;
        }

        .txt-poren {
            position: absolute;
            top: 70px;
            left: 90px;
            color: #292C35;
            font-size: 14px;
        }

        .txt-copy {
            position: absolute;
            top: 95px;
            left: 90px;
            color: #292C35;
            font-size: 14px;
        }

        .txt-address {
            width: 150px;
            position: absolute;
            top: 120px;
            left: 90px;
            color: #292C35;
            font-size: 12px;
        }
    }
}
.footer_Content_Center {
    display: flex;
    // border: 1px solid blue;

    .showFooter-box {
        // width: 300px;
        height: 300px;
        // border: 1px solid blue;
        margin-left: 40px;

        span {
            display: inline-block;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
            font-size: 18px;
            cursor: pointer;
        }

        .span-txt1 {
            margin-top: 98px;
            margin-bottom: 18px;
        }
    }
}
}
</style>