import Vue from 'vue';
import VueRouter from 'vue-router';
const HomePage = () => import('../views/HomePage'); //主页
const Trailer = () => import('../views/Trailer/Trailer.vue'); //拖车
const RentalBoxOnHand = () => import('../views/RentalBoxOnHand.vue'); //租箱 -> 租箱现货

const Inquiry = () => import("../views/Inquiry/Inquiry.vue");//立即订箱
const BoxRentalAndPre_Sale = () => import("../views/BoxRentalAndPre_Sale.vue"); //租箱预售
const EnquiryCabinet = () => import("../views/EnquiryCabinet.vue");//询柜
const OceanShipping = () => import("../views/EnquiryCabinet/OceanShipping.vue");//海运询柜
const Railway = () => import("../views/EnquiryCabinet/Railway.vue");//铁路询柜
const InquiryDetails = () => import("../views/EnquiryCabinet/InquiryDetails.vue") // 询价详情
const AirTransport = () => import("../views/EnquiryCabinet/AirTransport.vue"); //空运询柜
const BookingSpace = () => import("../views/EnquiryCabinet/BookingSpace.vue");//陆运订舱
const BookingSpaceoceanShipping = () => import("../views/EnquiryCabinet/BookingSpaceoceanShipping.vue");//海运订舱
const submitSuccess = () => import("../views/EnquiryCabinet/submitSuccess.vue")
const CargoTracking = () => import("../views/CargoTracking/CargoTracking.vue"); //货物追踪
const NewsCenter = () => import("../views/NewsCenter/NewsCenter.vue");  //新闻中心
const NewsCenterDetail = () => import("../views/NewsCenter/NewsCenterDetail.vue");
const CheckoutSuccess = () => import("../views/Inquiry/CheckoutSuccess.vue"); //下单成功

const PersonalCenter = () => import("../views/PersonalCenter/PersonalCenter.vue"); //个人中心
const MyHomePage = () => import("../views/PersonalCenter/MyHomePage.vue"); //我的主页
const TrailerOrder = () => import("../views/PersonalCenter/TrailerOrder.vue");//拖车订单
const MyOrder = () => import("../views/TrailerOrder/MyOrder.vue") //我的订单
const Transport = () => import("../views/TrailerOrder/Transport.vue"); //运输追踪
const packingListQuery = () => import("../views/TrailerOrder/packingListQuery.vue");//箱单查询
const ExpenseConfirmation = () => import("../views/TrailerOrder/ExpenseConfirmation.vue");//费用确认
const CounterInquiryOrder = () => import("../views/PersonalCenter/CounterInquiryOrder.vue"); //询柜订单
const OceanShippings = () => import("../views/PersonalCenter/OceanShipping.vue"); //海运订单
const Railways = () => import("../views/PersonalCenter/Railway.vue"); //铁路订单
const AirTransports = () => import("../views/PersonalCenter/AirTransport.vue"); //空运订单
const ReportManagement = () => import("../views/PersonalCenter/ReportManagement.vue");//报表管理
const HistoricalReport = () => import("../views/ReportForm/HistoricalReport"); //历史订单
const InTransitReport = () => import("../views/ReportForm/InTransitReport");//在途
const RentBoxs = () => import("../views/PersonalCenter/RentBox.vue"); //租箱订单
const FinancialStatement = () => import("../views/PersonalCenter/FinancialStatement.vue");//财务帐单
const MessageManagement = () => import("../views/PersonalCenter/MessageManagement.vue"); //消息管理
const EmployeeManagement = () => import("../views/PersonalCenter/EmployeeManagement.vue"); //员工管理
const RoleManagement = () => import("../views/PersonalCenter/RoleManagement.vue"); //角色管理
const MyAddresss = () => import("../views/PersonalCenter/MyAddresss.vue"); //我的地址

const Authen = () => import('../views/Authen/Authen.vue'); //认证信息

// NewsCenter
Vue.use(VueRouter);
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage //主页
  }, {
    path: '/Trailer',
    name: "Trailer",
    component: Trailer,//拖车
  },
  {
    path: "/RentalBoxOnHand",
    name: "RentalBoxOnHand",
    component: RentalBoxOnHand, //租箱现货
  },
  {
    path: "/Inquiry",
    name: "Inquiry",
    component: Inquiry,   //立即订箱
  },
  {
    path: "/BoxRentalAndPre_Sale",
    name: "BoxRentalAndPre_Sale",
    component: BoxRentalAndPre_Sale, //租箱预售
  },
  {
    path: "/EnquiryCabinet",
    name: "EnquiryCabinet",
    component: EnquiryCabinet, //询价
    redirect: "/EnquiryCabinet/OceanShipping",
    children: [
      {
        path: "OceanShipping",
        name: "OceanShipping",
        component: OceanShipping //海运询价
      },
      {
        path: "Railway",
        name: "Railway",
        component: Railway //铁路询价
      },
      {
        path: "AirTransport",
        name: "AirTransport",
        component: AirTransport, //空运询价
      },
      {
        path: "BookingSpace",
        name: "BookingSpace",
        component: BookingSpace, //陆运订舱
      },
      {
        path: "BookingSpaceoceanShipping",
        name: "BookingSpaceoceanShipping",
        component: BookingSpaceoceanShipping,
      }
      ,
      {
        path: "submitSuccess",
        name: "submitSuccess",
        component: submitSuccess, //提交成功
      }, {
        path: "InquiryDetails",
        name: "InquiryDetails",
        component: InquiryDetails, //询价详情
      }, {
        path: "CheckoutSuccess",
        name: "CheckoutSuccess",
        component: CheckoutSuccess
      }
    ]
  },
  {
    path: "/CargoTracking",
    name: "CargoTracking",
    component: CargoTracking //货物追踪
  },
  {
    path: "/NewsCenter",
    name: "NewsCenter",
    component: NewsCenter, //新闻中心
  },
  {
    path: "/NewsCenterDetail",
    name: "NewsCenterDetail",
    component: NewsCenterDetail,//新闻详情
  },
  {
    path: "/PersonalCenter",
    name: "PersonalCenter",
    component: PersonalCenter, //个人中心
    children: [
      {
        path: "MyHomePage",
        name: "MyHomePage",
        component: MyHomePage,//我的主页
      },
      {
        path: "TrailerOrder",
        name: "TrailerOrder",
        component: TrailerOrder,//拖车订单
        redirect: "/PersonalCenter/TrailerOrder/MyOrder",
        children: [
          {
            path: "MyOrder",
            name: "MyOrder",
            component: MyOrder
          },
          {
            path: "Transport",
            name: "Transport",
            component: Transport
          }, {
            path: "packingListQuery",
            name: "packingListQuery",
            component: packingListQuery
          }, {
            path: "ExpenseConfirmation",
            name: "ExpenseConfirmation",
            component: ExpenseConfirmation
          }
        ]
      },
      {
        path: "CounterInquiryOrder",
        name: "CounterInquiryOrder",
        component: CounterInquiryOrder,//询柜订单
      },
      {
        path: "OceanShippings",
        name: "OceanShippings",
        component: OceanShippings,//海运订单
      },
      {
        path: "Railways",
        name: "Railways",
        component: Railways, //铁路订单
      },
      {
        path: "AirTransports",
        name: "AirTransports",
        component: AirTransports,//空运订单
      },
      {
        path: "ReportManagement",
        name: "ReportManagement",
        component: ReportManagement,//报表管理
        redirect: "/PersonalCenter/ReportManagement/InTransitReport",
        children: [{
          path: "HistoricalReport",
          name: "HistoricalReport",
          component: HistoricalReport
        },
        {
          path: "InTransitReport",
          name: "InTransitReport",
          component: InTransitReport
        }]
      },
      {
        path: "RentBoxs",
        name: "RentBoxs",
        component: RentBoxs, //租箱订单
      },
      {
        path: "FinancialStatement",
        name: "FinancialStatement",
        component: FinancialStatement,//财务帐单
      },
      {
        path: "MessageManagement",
        name: "MessageManagement",
        component: MessageManagement,//消息管理
      },
      {
        path: "EmployeeManagement",
        name: "EmployeeManagement",
        component: EmployeeManagement, //员工管理
      },
      {
        path: "RoleManagement",
        name: "RoleManagement",
        component: RoleManagement, //角色管理
      },
      {
        path: "MyAddresss",
        name: "MyAddresss",
        component: MyAddresss, //我的地址
      }
    ]
  },
  {
    path: "/Authen",
    name: "Authen",
    component: Authen, //认证信息
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router
