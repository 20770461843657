<template>
  <div class="hander-Box">
    <div class="header-Nav">
      <div class="header-Content">
        <div class="header-Logo1" @click="handleLogo">
          <img src="../assets/BookingSpace/LOGO1.png" alt="">
        </div>
        <div class="header-Center">
          <ul>
            <li>
              <span>
                <router-link to="/" exact>侨链国际</router-link>
              </span>
            </li>
            <li>
              <span>
                <router-link to="/Trailer">拖车</router-link>
              </span>
            </li>
            <li>
              <span>
                <!-- '/RentalBoxOnHand'||'/BoxRentalAndPre_Sale' -->
                <router-link :to="flag ? '/RentalBoxOnHand' : '/BoxRentalAndPre_Sale'">租箱</router-link>
              </span>
              <div class="rentBox">
                <p @click="RentalBoxOnHand">
                  <router-link to="/RentalBoxOnHand">租箱现货</router-link>
                </p>
                <!-- BoxRentalAndPre_Sale     -->
                <p @click="BoxRentalAndPre_Sale">
                  <router-link to="/BoxRentalAndPre_Sale">租箱预售</router-link>
                </p>
              </div>
            </li>
            <li>
              <router-link to="/EnquiryCabinet">询价</router-link>
              <div class="transport">
                <p>

                  <router-link to="/EnquiryCabinet/OceanShipping">海运询价</router-link>
                </p>
                <p>
                  <!-- railway -->
                  <router-link to="/EnquiryCabinet/Railway">陆运询价</router-link>
                </p>
                <p>
                  <!-- AirTransport -->
                  <router-link to="/EnquiryCabinet/AirTransport">空运询价</router-link>

                </p>
              </div>

            </li>
            <li>
              <span>
                <!-- CargoTracking -->
                <router-link to="/CargoTracking">货物追踪</router-link>
              </span>
            </li>
            <li><span>
                <router-link to="/NewsCenter">新闻中心</router-link>
              </span></li>
          </ul>
        </div>
        <div class="header-Member">
          <div class='header-Member_Wei' v-if="confirmLogin">
            <span @click="handleLogin">登录</span>
            <span>|</span>
            <span @click="handleRegister">注册</span>
          </div>
          <div class="header-Member_Login" v-else>
            <img src="../assets/BookingSpace/touxiang.png" alt="">
            <span class="span_1">{{ cell_phoneNumber1 }}</span>
            <span class="span_2" v-if="authInfo !== 'Y'" @click="handleauthen">未认证</span>
            <div class="header-Member_Login_Hover">
              <ul>
                <!-- :class="isActive==index?'active':''" -->
                <li v-for="(item, index) in personalHomepage" :key="index">
                  <router-link class="aa" :to="'/PersonalCenter/' + item.pathRoute">
                    <div class="img"><img
                        :src="path == '/PersonalCenter/' + item.pathRoute || path == `/PersonalCenter/${item.pathRoute}/InTransitReport` || path == `/PersonalCenter/${item.pathRoute}/HistoricalReport` || path == `/PersonalCenter/${item.pathRoute}/MyOrder` || path == `/PersonalCenter/${item.pathRoute}/Transport` || path == `/PersonalCenter/${item.pathRoute}/packingListQuery` || path == `/PersonalCenter/${item.pathRoute}/ExpenseConfirmation` ? item.xuanImg : item.weiImg"
                        alt=""></div>
                    <div class="nameItem"><span>{{ item.name }}</span></div>
                  </router-link>

                </li>
                <div class="logOut" @click="handleLogOut">
                  <div class="img"><img src="../assets/myHomePage/logoOut.png" alt=""></div> <span>退出登录</span>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 登录   -->
    <div class="login_Box" v-show="loginloginWindow" ref="loginWindow">
      <!-- 密码登录 -->
      <div class="login" ref='loginss'>
        <div class="login_Title">
          <p>密码登录</p>
          <span class="loginClose" @click="handleLoginClose">X</span>
        </div>
        <div class="login_Input">
          <div class="input1">
            <input type="text" v-model="cell_phoneNumber" placeholder="请输入手机号">
          </div>
          <div class="input2">
            <input type="password" placeholder="请输入密码" v-model="loginPassword">
          </div>
        </div>
        <!-- 忘记密码 -->
        <div class="forgotPassword">
          <span>忘记密码？</span>
          <span @click="handleResetPassword">重置密码</span>
        </div>
        <!-- 登录按钮 -->
        <div class="login_Button" @click="handleLogin_2">
          <span>登录</span>
        </div>

        <!-- 新用户 -->
        <div class="newUser">
          <span>新用户？</span>
          <span @click="handleCreateAccount">创建账户</span>
        </div>
      </div>
    </div>

    <!-- 重置密码 -->
    <div class="resetPassword_Box" v-if="resetPasswordWindow">
      <div class="resetPassword">
        <div class="resetPassword_Title"><span>重置密码</span><span class="resetPasswordClose"
            @click="handleresetPasswordClose">X</span></div>
        <div class="resetPassword_Input">
          <div class="inputs"><input type="text" v-model="resetPasswordPhone" placeholder="请输入手机号"></div>
          <div class="inputs"><input type="text" v-model="resetPasswordCheck" placeholder="请输入验证码">
            <div class="chongzhihuoqu" @click="getVerificationCode" v-if="resetPass"><span>获取验证码</span></div>
            <div class="chongzhi" v-else><span>{{ countDownNum1 }} 秒后重新获取</span></div>
          </div>
          <div class="inputs"><input type="password" v-model="resetPassword1" placeholder="请输入新密码"></div>
          <div class="inputs"><input type="password" v-model="resetPassword2" placeholder="请再次输入新密码"></div>
        </div>
        <div class="forgotPassword">
          <span>已有账号？</span>
          <span @click="handleLogin">立即登录</span>
        </div>
        <div class="resetPassword_Button" @click="resetPassword"><span>重置</span></div>
        <div class="newUser">
          <span>新用户？</span>
          <span @click="handleCreateAccount">创建账户</span>
        </div>
      </div>
    </div>

    <!-- 新用户登录/注册 -->
    <div class="register_Box" v-if="registerWindow">
      <div class="register">
        <div class="register_Title"><span>新用户注册</span><span class="registerClose" @click="handleRegisterClose">X</span>
        </div>
        <div class="register_Input">
          <div class="inputs"><input type="text" v-model="registeredMobileNumber" placeholder="请输入手机号"></div>
          <div class="inputs"><input type="text" v-model="registrationVerificationCode" placeholder="请输入验证码">
            <div class="huoqu" @click="sendVerificationCode" v-if="timerTf"><span>获取验证码</span></div>
            <div class="daojishi" v-else><span>{{ countDownNum }} 秒后重新获取</span></div>
          </div>
          <div class="inputs"><input type="password" v-model="registrationPassword" placeholder="请输入密码"></div>
          <div class="inputs"><input type="password" v-model="registrationPassword2" placeholder="请再次输入密码"></div>
        </div>
        <!-- 忘记密码 -->
        <div class="forgotPassword">
          <span>已有账号？</span>
          <span @click="handleLogin">立即登录</span>
        </div>
        <!-- 注册按钮  register-->
        <div class="register_Button" @click="handleRegisterBtn">
          <span>注册</span>
        </div>
        <div class="newUser">
          <div class="check"><input v-model="registerBtn" type="checkbox"></div>
          <div class="agreement">
            <span>我同意</span>
            <span @click="handleZhuCe">注册协议</span>和
            <span @click="handleYinSi">隐私条款</span>
          </div>
        </div>

      </div>
    </div>

    <Verify ref="verify" :captcha-type="'blockPuzzle'" :img-size="{ width: '400px', height: '200px' }" @success="login"
      :registeredmobilenumber="registeredMobileNumber" />


    <router-view></router-view>

    <el-dialog title="" :visible.sync="flagHeYue" width="65%" top="1%" :before-close="handNOypcd">
          <!-- 注册协议 -->
    <div class="contractTerms">
      <div class="contractTermsBox">
        <!-- <div class="changeClose" @click="flagHeYue = !flagHeYue">X</div> -->
        <div class="contentS">
          <div class="contracTitle">注册协议
          </div>
          <div class="contracContent" v-html="basicagent">
            {{ basicagent }}
          </div>
          <div class="contracBot">
            <!-- <div class="conLeft">
            <div class="img" @click="agree">
              <img :src="registerBtn ? check : unchecked" alt="">
            </div>
            <p>
              <span>我已阅读 </span>
              <span>《国际货物进（出）口运输代理条款》</span>
            </p>
          </div> -->
            <div class="conRight">
              <!-- <div class="conRight1" @click="flagHeYue = !flagHeYue">上一步</div> -->
              <div class="conRight2" @click="handleMe"><span>我同意</span><span>注册协议</span>和<span>隐私条款</span></div>
            </div>
          </div>
        </div>

      </div>
    </div> 
    </el-dialog>

    <el-dialog title="" :visible.sync="flagHeYue1" width="65%" top="1%" :before-close="handNOypcd">
      <div class="contractTerms">
      <div class="contractTermsBox">
        <div class="contentS">
          <div class="contracTitle">隐私条款</div>
          <!-- <div class="changeClose" @click="flagHeYue1 = !flagHeYue1">X</div> -->
          <div class="contracContent" v-html="basicagent">
            {{ basicagent }}
          </div>
          <div class="contracBot">
            <!-- <div class="conLeft">
            <div class="img" @click="agree">
              <img :src="registerBtn ? check : unchecked" alt="">
            </div>
            <p>
              <span>我已阅读 </span>
              <span>《国际货物进（出）口运输代理条款》</span>
            </p>
          </div> -->
            <div class="conRight">
              <!-- <div class="conRight1" @click="flagHeYue1 = !flagHeYue1">上一步</div> -->
              <div class="conRight2" @click="handleMe"><span>我同意</span><span>注册协议</span>和<span>隐私条款</span></div>
            </div>
          </div>
        </div>

      </div>
    </div>
        </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { baseUrl } from '../tools/baseUrl';
import { postSedSms, postlogin, postregister, postrestPwd } from '../tools/ajax'
import { mapState } from 'vuex'
import Qs from 'qs'
import Verify from '../components/verifition/Verify.vue'
export default {
  name: "NavigaTionBar",
  components: { Verify },
  data() {
    return {
      flagHeYue: false,
      flagHeYue1: false,
      verify: false,
      flag: true,
      path: '',
      authInfo: '',
      loginloginWindow: false, //密码登录弹窗
      resetPasswordWindow: false, //密码重置弹窗
      registerWindow: false, //新用户登录弹窗
      confirmLogin: true,  //是否登录
      cell_phoneNumber: '',  //登录手机号
      cell_phoneNumber1: '',  //手机号
      loginPassword: "",  //密码
      isActive: null,

      registeredMobileNumber: '', //注册手机号
      zhuceMobile: "",
      registrationVerificationCode: "", //注册验证码
      registrationPassword: "",  //请输入密码
      registrationPassword2: "", //
      registerBtn: false,  //是否选择协议

      resetPasswordPhone: "", //重置手机号
      resetPasswordCheck: "",//重置验证码
      resetPassword1: "",//重置密码
      resetPassword2: "", //确认重置密码
      personalHomepage: [
        {
          weiImg: require("../../src/assets/homePage/myHomePage-N.png"),
          xuanImg: require("../../src/assets/homePage/myHomePage-Y.png"),
          name: "我的主页",
          pathRoute: "MyHomePage"
        },
        {
          weiImg: require("../../src/assets/homePage/tuoche-N.png"),
          xuanImg: require("../../src/assets/homePage/tuoche-Y.png"),
          name: "拖车订单",
          pathRoute: "TrailerOrder"
        },
        {
          weiImg: require("../../src/assets/homePage/order-N.png"),
          xuanImg: require("../../src/assets/homePage/order-Y.png"),
          name: "询价订单",
          pathRoute: "CounterInquiryOrder"
        },
        {
          weiImg: require("../../src/assets/homePage/oceanShipping-N.png"),
          xuanImg: require("../../src/assets/homePage/oceanShipping-Y.png"),
          name: "海运订单",
          pathRoute: "OceanShippings"
        },
        {
          weiImg: require("../../src/assets/homePage/railway-N.png"),
          xuanImg: require("../../src/assets/homePage/railway-Y.png"),
          name: "铁路订单",
          pathRoute: "Railways"
        },
        {
          weiImg: require("../../src/assets/homePage/airTransport-N.png"),
          xuanImg: require("../../src/assets/homePage/airTransport-Y.png"),
          name: "空运订单",
          pathRoute: "AirTransports"
        },
        {
          weiImg: require("../../src/assets/homePage/baobiao-N.png"),
          xuanImg: require("../../src/assets/homePage/baobiao-Y.png"),
          name: "报表管理",
          pathRoute: "ReportManagement" //ReportManagement

        },
        {
          weiImg: require("../../src/assets/homePage/rentBox-N.png"),
          xuanImg: require("../../src/assets/homePage/rentBox-Y.png"),
          name: "租箱订单",
          pathRoute: "RentBoxs"
        },
        {
          weiImg: require("../../src/assets/homePage/bill-N.png"),
          xuanImg: require("../../src/assets/homePage/bill-Y.png"),
          name: "财务账单",
          pathRoute: "FinancialStatement"
        },
        {
          weiImg: require("../../src/assets/homePage/myHomePage-N.png"),
          xuanImg: require("../../src/assets/homePage/myHomePage-Y.png"),
          name: "消息管理",
          pathRoute: "MessageManagement"
        },
        // {
        //   weiImg: require("../../src/assets/homePage/staff-N.png"),
        //   xuanImg: require("../../src/assets/homePage/staff-Y.png"),
        //   name: "员工管理",
        //   pathRoute: "EmployeeManagement"
        // },
        // {
        //   weiImg: require("../../src/assets/homePage/role-N.png"),
        //   xuanImg: require("../../src/assets/homePage/role-Y.png"),
        //   name: "角色管理",
        //   pathRoute: "RoleManagement"
        // },
        {
          weiImg: require("../../src/assets/homePage/address-N.png"),
          xuanImg: require("../../src/assets/homePage/address-Y.png"),
          name: "我的地址",
          pathRoute: "MyAddresss"
        }
      ],
      responseResult: "",
      // baseUrl: 'https://testh.ceecexpo.com/pcApi'
      renzheng: "",

      basicagent: '',//合约信息
      check: require('../assets/homePage/check-Y.png'), //选中
      unchecked: require('../assets/homePage/check-N.png'), //未选中

      timerTf: true, //发送验证码
      countDownNum: 60, //注册
      timer: null,

      resetPass: true, //重置密码
      countDownNum1: 60, //重置倒计时
      timer1: null,
    }
  },
  created() {
    this.$store.commit("loginloginWindow", this.loginloginWindow)
    this.path = this.$route.path;
    this.getauthenView()
  },

  mounted() {
    let uesrInfo = sessionStorage.getItem("token");
    let authInfo = JSON.parse(localStorage.getItem("authInfo"));
    let phones = localStorage.getItem("phone");
    this.cell_phoneNumber1 = phones
    this
    if (authInfo) {
      this.authInfo = authInfo.status || '';
    }

    if (this.$store.state.token || uesrInfo) {
      this.confirmLogin = false;
      // this.authen = 'Y'
    }
    // console.log(authInfo.status);
    // document.addEventListener("click", (e) => {
    //   if (e.target.className == "login_Box") {
    //     this.loginloginWindow = false
    //   } else if (e.target.className == "resetPassword_Box") {
    //     this.resetPasswordWindow = false
    //   } else if (e.target.className == "register_Box") {
    //     this.registerWindow = false
    //   }
    // })
  },
  provide() {
    return {
      chainStore: this.countDown
    }
  },
  methods: {
    getauthenView() {  //认证信息
      var token = sessionStorage.getItem("token");
      if (token) {
        axios.get(`${baseUrl}/authen/view`, {
          headers: {
            'authorization': token
          }
        }).then(res => {
          if (res.data.success == true) {
            this.authInfo = res.data.result.status;
          } else {
            let message = res.data.message;
            console.log(message);
            this.$message({
              showClose: true,
              message: message,
              type: 'error'
            });
            return false
          }
        }).catch(err => {
          console.error(err);
        })
      }

    },
    handleLoginClose() { //关闭登录框
      this.loginloginWindow = false;
    },
    handleRegisterClose() { //关闭注册框
      this.registerWindow = false;
    },
    handleresetPasswordClose() { //关闭重置密码框
      this.resetPasswordWindow = false;
    },

    handleLogOut() { //退出登录
      // console.log(this.authorization);
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.$store.commit("set_token", '')
      this.$store.commit("set_authInfo", '')
      this.confirmLogin = true;
      this.$router.replace("/");
    },
    handleLogo() {  //点击Logo
      this.$router.push("/")
    },
    RentalBoxOnHand() {
      this.flag = true
    },
    BoxRentalAndPre_Sale() {
      this.flag = false
    },

    handleLogin() { //登录
      this.loginloginWindow = true;
      this.resetPasswordWindow = false;
      this.registerWindow = false;
    },
    handleauthen() { //认证
      let subAccount = localStorage.getItem("subAccount");
      if (subAccount == 'false') { this.$router.push("/Authen") }


    },
    // #region //登录按钮
    handleLogin_2() {
      // console.log(this.cell_phoneNumber);   15736953769
      var phoneReg = /(^1\d{10}$)|(^[0-9]\d{7}$)/;  //手机号验证
      if (!phoneReg.test(this.cell_phoneNumber)) {
        this.$message.error('手机号或密码错误');
        return false;
      } else if (this.loginPassword == '') {
        this.$message({
          message: '请输入密码  ',
          type: 'warning'
        });
        return false
      }

      let data = Qs.stringify({ "mobile": `${this.cell_phoneNumber}`, "password": `${this.loginPassword}` });
      axios.post(`${baseUrl}/member/login`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }

      )
        .then(res => {
          // console.log(res);
          this.responseResult = JSON.stringify(res.data);
          if (res.data.success == false) {
            this.$message.error('手机号密码错误');
            return false

          } else if (res.data.success == true) {
            console.log(res.data.result.subAccount);
            // this.authen = res.data.result.authInfo.status;
            this.$message({
              showClose: true,
              message: '登录成功',
              type: 'success'
            });

            this.confirmLogin = false;
            this.loginloginWindow = false;
            this.cell_phoneNumber1 = this.cell_phoneNumber;
            this.$emit("change", this.loginloginWindow_1)
            var authorization = res.headers.authorization;
            // console.log("authorization" + authorization)
            this.$store.commit("set_phone", this.cell_phoneNumber)
            this.$store.commit("set_token", authorization);
            this.$store.commit("set_subAccount", res.data.result.subAccount)
            if (res.data.result.authInfo) {
              this.$store.commit("set_authInfo", res.data.result.authInfo);
            }

            if (res.data.result.authInfo.status) {
              this.authInfo = res.data.result.authInfo.status;
            }

            this.renzheng = res.data.result.authInfo;
            // console.log(this.renzheng);
            this.cell_phoneNumber = this.loginPassword = ''

          }
          else {
            this.$emit("change", this.loginloginWindow_1)
            // console.log(this.cell_phoneNumber);
            this.confirmLogin = false;
            this.loginloginWindow = false;
          }
        })
    },

    handleResetPassword() {//点击重置密码
      this.loginloginWindow = false;
      this.resetPasswordWindow = true;
      this.registerWindow = false;
      // console.log("$$$");

    },
    handleCreateAccount() {  //创建账户
      this.loginloginWindow = false;
      this.registerWindow = true
    },

    handleRegister() { //点击注册
      this.registerWindow = true
    },
    // #endregion


    // --新用户登录
    // #region
    login(params) {
      // if (this.loginName == 'admin' && this.loginPassword == '123456') {
      //   this.$router.push('/useOnline/sliderFixed')
      // } else {
      //   this.$message({
      //     message: '输入测试账号密码',
      //     type: 'warning'
      //   })
      // }
    },
    countDown() {  //倒计时
      this.timerTf = false;
      this.timer = setInterval(() => {
        this.countDownNum--
        if (this.countDownNum <= 0) {
          this.timerTf = true
          clearInterval(this.timer)
        }
      }, 1000)
    },
    sendVerificationCode() { //获取验证码
      // var res =/^\d{6}$/;  //匹配6位数验证码
      // if(!res.test(this.registeredMobileNumber)) return false

      if (this.registeredMobileNumber == '') {
        this.$message({
          showClose: true,
          message: '请输入手机号',
          type: 'error'
        });
      } else {
        // this.verify = true;
        sessionStorage.setItem("registeredMobileNumber", this.registeredMobileNumber)

        this.$refs.verify.show()

        let data = Qs.stringify({ "mobile": `${this.registeredMobileNumber}`, "type": 0 });
        // axios.post(`${baseUrl}/member/sedSms`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        //   .then(res => {
        //     // console.log(res)
        //     if (res.data.success == true) {
        //       this.timerTf = false;
        //       this.timer = setInterval(() => {
        //         this.countDownNum--
        //         if (this.countDownNum <= 0) {
        //           this.timerTf = true
        //           clearInterval(this.timer)
        //         }
        //       }, 1000)
        //     } else {
        //       let messages = res.data.aliyun.raw.Message;
        //       this.$message({
        //         showClose: true,
        //         message: messages,
        //         type: 'error'
        //       });
        //     }

        //   })
        //   .catch(err => {
        //     console.error(err);
        //   })
      }

      // postSedSms({ mobile: this.registeredMobileNumber, type: 0 }).then(res => {  //验证码请求  //193042
      //   console.log("yan", res);
      // })

    },
    handleRegisterBtn() { //注册账号 15736953769

      var phoneReg = /(^1\d{10}$)|(^[0-9]\d{7}$)/;  //手机号验证
      var verificationCode = /^\d{6}$/;  //验证码
      if (!phoneReg.test(this.registeredMobileNumber)) {
        this.$message.error({
          message: '手机号错误'
        })
        return false;
      } else if (!verificationCode.test(this.registrationVerificationCode)) {
        this.$message.error({
          message: '验证码错误'
        })
        return false;
      } else if (this.registrationPassword !== this.registrationPassword2) {
        this.$message.error({
          message: '两次密码不一致'
        })
        return false;
      } else if (this.registrationPassword == '' || this.registrationPassword2 == '') {
        this.$message.error({
          message: '密码不能为空'
        })
        return false;
      } else if (!this.registerBtn) {
        this.$message.error({
          message: '请勾选协议'
        })
        return false;
      } else {
        let data = Qs.stringify({ "mobile": `${this.registeredMobileNumber}`, "password": `${this.registrationPassword}`, "code": `${this.registrationVerificationCode}` });
        axios.post(`${baseUrl}/member/register`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(res => {
            // console.log(res)

            if (res.data.success == true) {
              this.$message({
                message: '注册成功',
                type: 'success'
              });
              var authorization = res.headers.authorization;
              // console.log("authorization" + authorization)
              this.$store.commit("set_phone", this.registeredMobileNumber)
              this.$store.commit("set_token", res.data.result.token);
              if (res.data.result.authInfo) {
                this.$store.commit("set_authInfo", res.data.result.authInfo);
              }

              if (res.data.result.authInfo) {
                this.authInfo = res.data.result.authInfo;
              }
              // this.loginloginWindow = true;
              // this.cell_phoneNumber = res.data.result.mobile;
              // this.loginPassword = res.data.result.password;
              this.registerWindow = false;
              location.reload();

            } else if (res.data.success == false) {
              let messages = res.data.message
              this.$message({
                showClose: true,
                message: messages,
                type: 'warning'
              });
              return false

            }
          })
          .catch(err => {
            console.error(err);
          })
        // postregister({
        //   mobile: this.registeredMobileNumber,
        //   password: this.registrationPassword,
        //   code: this.registrationVerificationCode,
        // }).then((res) => {
        //   console.log("注册", res.data);
        //   if (res.code == 200) {
        //     this.$message({
        //       message: '注册成功',
        //       type: 'success'
        //     });
        //     this.loginloginWindow = true
        //   } else if (res.code == 500) {
        //     this.$message({
        //       showClose: true,
        //       message: '用户名已存在',
        //       type: 'warning'
        //     });

        //   } else if (res.code == 500) {
        //     this.$message.error('验证码错误');
        //   }
        // })
      }




      // console.log(this.registeredMobileNumber);
      // console.log(this.registrationVerificationCode);
    },

    // #endregion

    // resetPassword 重置密码
    // #region
    // resetPasswordPhone: "", //重置手机号
    // resetPasswordCheck: "",//重置验证码
    getVerificationCode() { //获取验证码
      var phoneReg = /(^1\d{10}$)|(^[0-9]\d{7}$)/;  //手机号验证
      if (!phoneReg.test(this.resetPasswordPhone)) {
        this.$message.error('手机号格式不正确');
        return false;
      }
      var type = 2;
      let data = Qs.stringify({ "mobile": `${this.resetPasswordPhone}`, "type": `${type}` });
      axios.post(`${baseUrl}/member/sedSms`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        .then(res => {
          // console.log(res)
          if (res.data.success == true) {
            this.resetPass = false;
            this.timer1 = setInterval(() => {
              this.countDownNum1--
              if (this.countDownNum1 <= 0) {
                this.resetPass = true
                clearInterval(this.timer1)
              }
            }, 1000)
          } else if (res.data.success == false) {
            let messages = res.data.message;
            this.$message({
              showClose: true,
              message: messages,
              type: 'error'
            });
          }
        })
        .catch(err => {
          console.error(err);
        })
      // postSedSms({ //15736953769
      //   mobile: this.resetPasswordPhone,
      //   type: 2
      // }).then((res) => {
      //   console.log(res);
      // })
    },
    resetPassword() {  //重置按钮
      var phoneReg = /(^1\d{10}$)|(^[0-9]\d{7}$)/;  //手机号验证
      var verificationCode = /^\d{6}$/;  //验证码
      if (!phoneReg.test(this.resetPasswordPhone)) {
        this.$message({
          showClose: true,
          message: '手机号格式不正确',
          type: 'error'
        });

        return false;
      } else if (!verificationCode.test(this.resetPasswordCheck)) {
        this.$message({
          showClose: true,
          message: '验证码不正确',
          type: 'error'
        });
      } else if (this.resetPassword1 !== this.resetPassword2) {
        this.$message({
          showClose: true,
          message: '两次输入密码不正确',
          type: 'error'
        });
        return false;
      } else if (this.resetPassword1.length <= 5) {
        this.$message({
          showClose: true,
          message: '密码长度不能小于6',
          type: 'error'
        });
        return false;
      } else {
        let data = Qs.stringify({ "mobile": `${this.resetPasswordPhone}`, "code": `${this.resetPasswordCheck}`, "newPwd": `${this.resetPassword1}` });
        axios.post(`${baseUrl}/member/restPwd`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(res => {
            // console.log(res)
            if (res.data.success == true) {
              this.$message({
                showClose: true,
                message: '修改成功',
                type: 'success'
              });
              this.resetPasswordWindow = false;
            } else {
              let messages = res.data.message;
              this.$message({
                showClose: true,
                message: messages,
                type: 'error'
              });
            }

          })
          .catch(err => {
            console.error(err);
          })
      }

      // postrestPwd({
      //   mobile: this.resetPasswordPhone,
      //   code: this.resetPasswordCheck,
      //   newPwd: this.resetPassword1
      // }).then((res) => {
      //   console.log("^^", res);
      // })
    },

    // #endregion
    // MyHomePage
    handlePush(pathRoute, index) {
      this.isActive = index;
      // console.log("@", this.isActive);
      this.$router.push(`/PersonalCenter/${pathRoute}`)
    },


    // 协议
    handleZhuCe() {   //注册协议
      this.flagHeYue = true;
      axios.get(`${baseUrl}/basic/agreement`, {
        headers: { 'Content-Type': 'h5' }
      }).then(res => {
        // console.log(res);
        this.basicagent = res.data.result.content;
        // this.basicagent = this.basicagent.replace(reg, "");
        // console.log(this.basicagent)
      })
        .catch(err => {
          console.error(err);
        })
    },

    handleYinSi() { //隐私条款
      this.flagHeYue1 = true;
      axios.get(`${baseUrl}/basic/privacy`, {
        headers: { 'Content-Type': 'h5' }
      }).then(res => {
        // console.log(res);
        this.basicagent = res.data.result.content;
        // this.basicagent = this.basicagent.replace(reg, "");
        console.log(this.basicagent)
      })
        .catch(err => {
          console.error(err);
        })
    },

    handleMe() { //注册协议
      this.flagHeYue = false;
      this.flagHeYue1 = false;
      this.registerBtn = true;
    },
    agree() {
      this.registerBtn = !this.registerBtn;
    },
  },

  watch: {
    $route() {
      this.path = this.$route.path
    },
    ...mapState(['authorization']),
    registeredMobileNumber(val) {
      this.registeredMobileNumber = val
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  background: #E9F4FF;


  .nameItem {
    color: #1890FF;
  }
}

.router-link-active {
  background: #E9F4FF;
  color: #1890FF !important;
  border-radius: 4px 4px 4px 4px;
  // padding: 8px 16px;
}

.contractTerms {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(51, 51, 51, 0.1);
  // background-color: white;
  width: 100%;
  height: 800px;
  overflow-y: auto;
  z-index: 200;
  background-color: #fff;
  .contractTermsBox {
    position: relative;
    width: 100%;
    // height: 700px;
    height: 100%;
    margin: 0px auto;
    // background: #FFFFFF;
    // overflow: hidden;
    // overflow-y: scroll;
    padding: 0 20px;

    .contentS {
      width: 1200px;
      margin: 0 auto;
      // overflow: hidden;
      // padding-top: 20px;
    }

    .changeClose {
      width: 100%;
      background-color: rgb(#000000, .6);
      position: absolute;
      right: 10px;
      top: 0px;
      font-size: 24px;
      text-align: right;
      cursor: pointer;
    }

    .contracTitle {
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 20px;
    }

    .contracContent {
      margin-top: 25px;
    }

    .contracBot {
      display: flex;
      justify-content: right;

      width: 100%;
      height: 64px;
      margin-top: 33px;
      background: #FFFFFF;

      .conLeft {
        display: flex;
        align-items: center;

        p {
          margin-left: 5px;
          line-height: 64px;
        }
      }

      .conRight {
        display: flex;

        .conRight1 {
          width: 150px;
          height: 40px;
          border-radius: 30px 30px 30px 30px;
          border: 1px solid #1890FF;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          font-weight: 400;
          margin-right: 59px;
          color: #1890FF;
          cursor: pointer;
        }

        .conRight2 {
          width: 228px;
          height: 40px;
          background: #1890FF;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          border-radius: 30px 30px 30px 30px;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }
}

.hander-Box {
  position: relative;
  width: 100%;
  // height: 100%;


  .header-Nav {
    width: 100%;
    height: 90px;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px 1px rgba(51, 51, 51, 0.1);
    margin: 0 auto;

    .header-Content {
      position: relative;
      display: flex;
      align-items: center;
      width: 1200px;
      height: 90px;
      margin: 0 auto;
      // overflow: hidden;

      //  Logo 
      .header-Logo1 {
        width: 220px;
        height: 47.38px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .header-Center {
        display: flex;
        align-items: center;
        margin-left: 40px;
        height: 100%;

        ul {
          display: flex;

          li {
            position: relative;
            height: 32px;
            line-height: 32px;
            font-size: 16px;
            color: #333333;
            margin-left: 32px;

            .rentBox {
              display: none;
              z-index: 100;
              position: absolute;
              left: -10px;
              bottom: -105px;
              width: 100px;
              height: 104px;
              background: #FFFFFF;
              box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
              border-radius: 8px 8px 8px 8px;
              z-index: 10;
              text-align: center;
              // overflow: hidden;

              p {
                margin-top: 12px;
                color: none !important;
                font-size: 16px;
                height: 40px;
                line-height: 40px;
              }

              p:hover {
                background: #E9F4FF;
                color: #1890FF;
              }
            }

            a {
              width: 100%;
              height: 100%;
              padding: 8px 16px;
              line-height: 16px;

              color: #333333;
            }

            .transport {
              display: none;
              position: absolute;
              left: -10px;
              bottom: -145px;
              width: 100px;
              height: 144px;
              background: #FFFFFF;
              z-index: 10;
              box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
              border-radius: 8px 8px 8px 8px;
              text-align: center;
              z-index: 100;

              p {
                height: 40px;
                line-height: 40px;

              }

              p:nth-child(1) {
                margin-top: 12px;
              }

              // oceanShipping
              p:hover {
                background: #E9F4FF;
                color: #1890FF;
              }


            }
          }

          li:hover .transport {
            z-index: 1200;
            display: block;
          }

          li:hover .rentBox {
            z-index: 1200;
            display: block;
          }

          // li:hover {
          //   background: #E9F4FF;
          // }

          li:hover span {

            font-weight: 400;
          }

          li:nth-child(1) {
            margin-left: 0;
          }

        }
      }

      .header-Member {
        position: absolute;
        right: 0;
        // margin-left: 200px;
        bottom: 50%;
        // transform: translateY(-50%);
        height: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        float: right;
        cursor: pointer;

        .header-Member_Wei {
          color: #D4252C;
        }

        .header-Member_Login {
          display: flex;
          align-items: center;
          padding: 9px 12px;
          width: 186px;
          height: 32px;
          background: #E9F4FF;
          border-radius: 4px 4px 4px 4px;
          text-align: center;
          // transform: translateY(-25%);

          img {
            width: 24px;
            height: 24px;
            // background: #FFFFFF;
            opacity: 1;
            // border: 1px solid #707070;
          }

          .span_1 {
            font-size: 14px;
            font-weight: 400;
            color: #333333 !important;
          }

          .span_2 {
            font-size: 14px;
            font-weight: 400;
            color: #1890FF;
            margin-left: 4px;
          }

          position: relative;

          .header-Member_Login_Hover {
            display: none;
            position: absolute;
            top: 32px;
            left: 50%;
            transform: translateX(-50%);
            width: 152px;
            // height: 441px;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 8px 8px 8px 8px;
            // z-index: 12200;
            padding: 12px 0;
            z-index: 9000;

            .router-link-exact-active {
              background: #E9F4FF;

              .nameItem {
                color: #1890FF !important;
              }
            }

            .aa {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            ul {
              li {
                display: flex;
                align-items: center;
                width: 100%;
                height: 40px;
                // background: #E9F4FF;
                justify-content: center;
                margin-top: 1px;


                .img {
                  width: 16px;
                  height: 16px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .nameItem {
                  margin-left: 13px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #333333;
                }
              }
            }

            .logOut {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 40px;
              margin-top: 1px;

              .img {
                width: 16px;
                height: 16px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              span {
                margin-left: 13px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
              }
            }
          }
        }

        .header-Member_Login:hover .header-Member_Login_Hover {
          display: block;
        }
      }
    }
  }


}

// 登录
.login_Box {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  // position: ;
  .login {
    position: absolute;
    top: 408px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    height: 378px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;

    .login_Title {
      position: relative;
      margin-top: 32px;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      height: 30px;
      line-height: 30px;

      .loginClose {
        position: absolute;
        right: 30px;
        top: 0;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .login_Input {
      width: 370px;
      height: 96px;
      margin: 64px auto 0px;

      div {
        width: 370px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #333333;

        input {
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 4px 4px;
          border: 0;
          outline: none;
          padding-left: 16px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }

      // .input1 {}

      .input2 {
        margin-top: 16px;
      }
    }

    .forgotPassword {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      margin-top: 32px;
      color: #333333;

      span:nth-child(2) {
        cursor: pointer;
        color: #D4252C;
      }
    }

    .login_Button {
      width: 370px;
      height: 40px;
      background: #1890FF;
      box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      line-height: 40px;
      margin: 12px auto 0;
      cursor: pointer;

      span {
        color: #FFFFFF;
      }
    }

    .newUser {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 12px;

      span:nth-child(2) {
        cursor: pointer;
        color: #D4252C;
      }
    }
  }
}

// 重置密码
.resetPassword_Box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  .resetPassword {
    position: absolute;
    left: 50%;
    top: 408px;
    transform: translate(-50%, -50%);
    width: 430px;
    height: 490px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;

    .resetPassword_Title {
      // width: 120px;
      height: 30px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
      margin-top: 32px;
      text-align: center;

      .resetPasswordClose {
        position: absolute;
        right: 30px;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .resetPassword_Input {
      margin-top: 64px;

      .inputs {
        width: 370px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        // margin-top: 16px;
        margin: 16px auto 0;
        overflow: hidden;

        input {
          border: 1px solid #333333;
          outline: none;
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 4px 4px;
          padding-left: 16px;
          font-size: 14px;
          color: #333333;
        }

        .chongzhi {
          width: 144px;
          height: 40px;
          background: rgba(153, 153, 153, 0.2) !important;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          color: #999999;
        }
      }

      .inputs:nth-child(1) {
        margin-top: 0;
      }

      .inputs:nth-child(2) {
        display: flex;
        justify-content: space-between;

        input {
          width: 210px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
        }

        .chongzhihuoqu {
          width: 144px;
          height: 40px;
          background: #1890FF;
          box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
          border-radius: 4px 4px 4px 4px;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }

    .forgotPassword {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-top: 30px;

      span:nth-child(1) {}

      span:nth-child(2) {
        font-weight: bold;
        color: #D4252C;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .resetPassword_Button {
      width: 370px;
      height: 40px;
      background: #1890FF;
      box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
      border-radius: 4px 4px 4px 4px;
      line-height: 40px;
      text-align: center;
      margin: 12px auto 0;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }

    .newUser {
      text-align: center;
      margin-top: 12px;
      font-weight: bold;
      color: #333333;

      span:nth-child(2) {
        font-weight: bold;
        color: #D4252C;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}

//新用户登录/注册
.register_Box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  .register {
    position: absolute;
    left: 50%;
    top: 408px;
    transform: translate(-50%, -50%);
    width: 430px;
    height: 490px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;

    .register_Title {
      position: relative;
      // width: 120px;
      height: 30px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
      margin-top: 32px;
      text-align: center;

      .registerClose {
        position: absolute;
        right: 30px;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .register_Input {
      margin-top: 64px;

      .inputs {
        width: 370px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        // margin-top: 16px;
        margin: 16px auto 0;
        overflow: hidden;

        input {
          border: 1px solid #333333;
          outline: none;
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 4px 4px;
          padding-left: 16px;
          font-size: 14px;
          color: #333333;
        }

        .daojishi {
          width: 144px;
          height: 40px;
          background: rgba(153, 153, 153, 0.2) !important;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          color: #999999;
        }
      }



      .inputs:nth-child(1) {
        margin-top: 0;
      }

      .inputs:nth-child(2) {
        display: flex;
        justify-content: space-between;

        input {
          width: 210px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
        }

        .huoqu {
          width: 144px;
          height: 40px;
          background: #1890FF;
          box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
          border-radius: 4px 4px 4px 4px;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
        }
      }

    }

    .forgotPassword {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      margin-top: 32px;
      color: #333333;

      span:nth-child(2) {
        cursor: pointer;
        color: #D4252C;
      }
    }

    .register_Button {
      width: 370px;
      height: 40px;
      background: #1890FF;
      box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      line-height: 40px;
      margin: 12px auto 0;
      color: #FFFFFF;
      cursor: pointer;
    }

    .newUser {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      // font-weight: bold;
      margin-top: 12px;
      color: #333333;

      .check {
        width: 18px;
        height: 18px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        // border: 1px solid #999999;

        input {
          width: 100%;
          height: 100%;
          // border: none;
          border-color: #999999;
          outline: none;
        }
      }

      .agreement {
        margin-left: 6px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;

        font-weight: bold;

        span:nth-child(2),
        span:nth-child(3) {
          color: #D4252C;
          margin: 0 6px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>