<template>
    <div class="SpecialLine">
        <div>
            <p class="SpecialLine_Title">
                <span>特价线路</span>
            </p>
            <div class="SpecialLine_Content">
                <ul>
                    <li v-for="(item, index) in recommendList" :key="index">
                        <div class="atMost">
                            <span>最多可订：</span>
                            <span>{{ item.maxBuy }}柜</span>
                        </div>
                        <div class="boxType">
                            <span>箱子类型：</span>
                            <span>{{ item.cupboard }}</span>
                        </div>
                        <div class="address">
                            <p>{{ item.departure }}</p>
                            <p>
                                <span>大约{{ item.period }}天</span>
                                <img src="../assets/homePage/departureAndArrival.png" alt="">
                            </p>
                            <p>{{ item.destination }}</p>
                        </div>
                        <div class="suitcase">
                            <p> <img src="../assets/homePage/export.png" alt=""> 提箱</p>
                            <p><img src="../assets/homePage/export.png" alt="" /> 还箱</p>
                        </div>
                        <div class="score">
                            <div class="score_Left">
                                <p>***</p>
                            </div>
                            <!-- 立即订箱 -->
                            <div class="score_Right">
                                <div class="bookImmediately" @click="handlebooking(item.id, item.boxType)">
                                    <h6>立即订箱</h6>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 优势 Recommend_Component Location -->
        <div class="advantage">
            <div class="advantageTitle">我们的优势</div>
            <div class="advantageTitle_2">
                <p>
                    侨链国际以国际物流为驱动，以集装箱为桥梁，通过自主研发系列SaaS管理系统，整合国际供应链上的各方资源，大量采用绿色低碳国际标准和资源，
                    打破信息壁垒，为用户提供供应链金融、订舱、清关、堆场、班列开列、拖车等全程服务
                </p>
            </div>
        </div>
        <!-- 销量 -->
        <div class="salesVolume">
            <div class="salesVolume_Content">
                <div class="salesVolume_Left">
                    <div class="img">
                        <img src="../assets/homePage/20.png" alt="">
                    </div>
                    <div class="num"> <span>50,000+</span> </div>
                    <div class="bottom"><span>智能集装箱</span></div>
                </div>
                <div class="salesVolume_Center">
                    <div class="img">
                        <img src="../assets/homePage/19.png" alt="">
                    </div>
                    <div class="num"> <span>200+</span> </div>
                    <div class="bottom"><span>堆场</span></div>
                </div>
                <div class="salesVolume_Right">
                    <div class="img">
                        <img src="../assets/homePage/21.png" alt="">
                    </div>
                    <div class="num"> <span>1,000,000+</span> </div>
                    <div class="bottom"><span>交易额</span></div>
                </div>
            </div>
        </div>

        <div class="paas">
            <div class="pass_Left">
                <div class="img">
                    <img src="../assets/homePage/116.png" alt="">
                </div>
                <div class="center">
                    <span>一个平台，全球流通</span>
                </div>
                <div class="bottom">
                    <span>侨链国际贸易平台系统，24小时联通全球物流网络,提供实时数据与精准分析，智能化图形界面，让贸易操作更加灵活。</span>
                </div>
            </div>
            <div class="pass_Center">
                <div class="img">
                    <img src="../assets/homePage/introResource.png" alt="">
                </div>
                <div class="center">
                    <span>资源整合，快速周转</span>
                </div>
                <div class="bottom">
                    <span>整合进出口商、货运公司、航空/海运/公路运输公司、海关、港口等贸易各方
                        机构.加速箱舱快速流通，提高运输工具与集装箱的使用效率。</span>
                </div>
            </div>
            <div class="pass_Right">
                <div class="img">
                    <img src="../assets/homePage/introSafe.png" alt="">
                </div>
                <div class="center">
                    <span>跨境结算，安全快速</span>
                </div>
                <div class="bottom">
                    <span>侨链国际支持跨境结算业务，资金更快更安全。</span>
                </div>
            </div>
            <div class="pass_Right">
                <div class="img">
                    <img src="../assets/homePage/ditan.png" alt="">
                </div>
                <div class="center">
                    <span>绿色低碳，智能计算</span>
                </div>
                <div class="bottom">
                    <span>为不同碳减排场景创新开发碳计算模型</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { recommend, postAddOrder } from '../tools/ajax'
import { mapState } from 'vuex'
import { userInfo } from 'os';
export default {
    name: "SpecialLine_Component",
    data() {
        return {
            recommendList: []
        }
    },

    created() {
        recommend().then(res => {
            // console.log(res);
            this.recommendList = res.result;
        });
    },
    computed: {
        ...mapState(['token', 'loginloginWindow']),


    },
    methods: {
        handlebooking(id, boxType) {

            let uesrInfo = sessionStorage.getItem("token");
            let authInfo = JSON.parse(localStorage.getItem("authInfo"));
            // console.log(authInfo.status )
            if (this.token == '' || uesrInfo == undefined || this.token == undefined) {
                this.$message({
                    showClose: true,
                    message: '您还没有登录',
                    type: 'error'
                });
                return false
            } else if (authInfo.status !== 'Y' && this.token) {
                // console.log("未认证");
                this.$message({
                    showClose: true,
                    message: '您还没有认证，请先去认证',
                    type: 'error',
                });
                return false
            } else if (this.token || uesrInfo) {
                this.$router.push({
                    path: "/Inquiry",
                    query: {
                        id: id,
                        boxType: boxType
                    }
                }
                )
            }


        }
    },
}
</script>

<style lang="scss" scoped>
.SpecialLine {
    width: 1200px;
    margin: 0 auto;
    padding-top: 54px;

    .SpecialLine_Title {
        font-size: 30px;
        font-family: "黑体";
        font-weight: 400;
        color: #2B2B2B;
        line-height: 24px;
        border-bottom: 1px solid #999999;
        padding-bottom: 16px;

        span {
            border-bottom: 1px solid #FFC13B;
            padding-bottom: 14px;
        }
    }

    .SpecialLine_Content {
        width: 100%;
        margin-top: 40px;

        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                box-sizing: border-box;
                width: 30%;
                height: 160px;
                background: linear-gradient(90deg, #FFFEFE 0%, #FFF8F5 100%);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid #707070;
                margin-right: 15px;
                margin-bottom: 12px;
                padding: 16px 17px 10px 16px;

                .atMost {
                    position: relative;
                    display: flex;
                    font-size: 12px;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 12px;
                    margin-bottom: 4px;
                    height: 12px;

                    span:nth-child(1) {
                        text-align: left;
                    }

                    span:nth-child(2) {
                        color: #D4252C;
                    }
                }

                .boxType {
                    font-size: 12px;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 12px;
                    height: 12px;
                }

                .address {
                    display: flex;
                    justify-content: space-between;
                    height: 20px;
                    margin-top: 24px;


                    p:nth-child(1) {
                        font-size: 20px;
                        font-weight: bold;
                        color: #1890FF;
                        line-height: 12px;
                    }

                    p:nth-child(2) {
                        font-size: 12px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 12px;

                        span {
                            display: block;
                        }
                    }

                    p:nth-child(3) {
                        font-size: 20px;
                        font-weight: bold;
                        color: #1890FF;
                        line-height: 12px;
                    }
                }

                .suitcase {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 12px;

                }

                .score {
                    display: flex;
                    justify-content: space-between;
                    height: 30px;
                    margin-top: 18px;

                    .score_Left {
                        font-weight: bold;
                        color: #D4252C;
                        line-height: 24px;
                        font-size: 22px;
                    }

                    .score_Right {
                        display: none;
                        width: 72px;
                        height: 30px;
                        background: #FFC13B;
                        border-radius: 4px 4px 4px 4px;
                        line-height: 30px;
                        text-align: center;

                        h6 {
                            font-weight: normal;
                            cursor: pointer;
                        }

                    }
                }

            }

            li:hover .score_Right {
                display: block;
            }

            li:hover {
                background: linear-gradient(90deg, #90C9FF 0%, #1890FF 100%);
                box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            }

            li:hover span {
                color: #FFFFFF !important;
            }

            li:hover p {
                color: #FFFFFF !important;
            }

            li:nth-child(n+3) {
                margin-right: 0;
            }

            li:nth-child(6) {
                margin-right: 0;
            }
        }

        ul:after {
            content: "";
            width: 30%;
            // flex: auto;
            border: 1px solid transparent;
        }
    }

    .advantage {
        width: 1200px;
        margin: 80px auto 24px;

        // margin-top: 27px;
        .advantageTitle {
            text-align: center;
            font-size: 25px;
            font-weight: 400;
            color: #2B2B2B;
            line-height: 24px
        }

        .advantageTitle_2 {
            height: 42px;
            margin: 24px auto 0;
            padding: 0 87px 54px 81px;
            font-family: '黑体';
            font-weight: 400;
            color: #2B2B2B;
            line-height: 24px;
            text-align: center;
            font-size: 18px;

        }
    }

    .salesVolume {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 1200px;
        height: 270px;
        border: 1px solid;
        background-image: url('../assets/homePage/salesVolume.png'); //salesVolume
        background-size: auto;
        margin-top: 40px;

        .salesVolume_Content {
            display: flex;
            justify-content: space-between;
            width: 1200px;
            height: 270px;
            z-index: 100;
        }

        .salesVolume_Left,
        .salesVolume_Center,
        .salesVolume_Right {
            margin: auto;
            flex: 1;
            z-index: 1;

            .img {
                // border: 1px solid;
                width: 70px;
                height: 70px;
                margin: auto;
            }

            .num {
                height: 60px;
                font-size: 44px;
                font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
                font-weight: bold;
                color: #FFC13B !important;
                line-height: 60px;
                text-align: center;
            }

            .bottom {
                text-align: center;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 24px;
            }
        }


    }

    .paas {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        // margin-top: 108px;
        margin: 108px auto 0;

        .pass_Left,
        .pass_Center,
        .pass_Right {
            flex: 1;

            .img {
                width: 250px;
                height: 146px;
                // border: 1px solid;
                margin: auto;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .center {
                margin: 32px auto 22px;
                font-size: 25px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #2B2B2B;
                line-height: 24px;
                text-align: center;
            }

            .bottom {
                font-size: 12px;
                // font-family: '黑体';
                font-weight: 400;
                color: #2B2B2B;
                line-height: 24px;
                text-align: center;
                padding: 0 5px;

                span {
                    line-height: 12px;
                    text-align: center;
                }
            }
        }
    }
}
</style>