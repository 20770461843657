var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"SpecialLine"},[_c('div',[_vm._m(0),_c('div',{staticClass:"SpecialLine_Content"},[_c('ul',_vm._l((_vm.recommendList),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"atMost"},[_c('span',[_vm._v("最多可订：")]),_c('span',[_vm._v(_vm._s(item.maxBuy)+"柜")])]),_c('div',{staticClass:"boxType"},[_c('span',[_vm._v("箱子类型：")]),_c('span',[_vm._v(_vm._s(item.cupboard))])]),_c('div',{staticClass:"address"},[_c('p',[_vm._v(_vm._s(item.departure))]),_c('p',[_c('span',[_vm._v("大约"+_vm._s(item.period)+"天")]),_c('img',{attrs:{"src":require("../assets/homePage/departureAndArrival.png"),"alt":""}})]),_c('p',[_vm._v(_vm._s(item.destination))])]),_vm._m(1,true),_c('div',{staticClass:"score"},[_vm._m(2,true),_c('div',{staticClass:"score_Right"},[_c('div',{staticClass:"bookImmediately",on:{"click":function($event){return _vm.handlebooking(item.id, item.boxType)}}},[_c('h6',[_vm._v("立即订箱")])])])])])}),0)])]),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"SpecialLine_Title"},[_c('span',[_vm._v("特价线路")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suitcase"},[_c('p',[_c('img',{attrs:{"src":require("../assets/homePage/export.png"),"alt":""}}),_vm._v(" 提箱")]),_c('p',[_c('img',{attrs:{"src":require("../assets/homePage/export.png"),"alt":""}}),_vm._v(" 还箱")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score_Left"},[_c('p',[_vm._v("***")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantage"},[_c('div',{staticClass:"advantageTitle"},[_vm._v("我们的优势")]),_c('div',{staticClass:"advantageTitle_2"},[_c('p',[_vm._v(" 侨链国际以国际物流为驱动，以集装箱为桥梁，通过自主研发系列SaaS管理系统，整合国际供应链上的各方资源，大量采用绿色低碳国际标准和资源， 打破信息壁垒，为用户提供供应链金融、订舱、清关、堆场、班列开列、拖车等全程服务 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"salesVolume"},[_c('div',{staticClass:"salesVolume_Content"},[_c('div',{staticClass:"salesVolume_Left"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/homePage/20.png"),"alt":""}})]),_c('div',{staticClass:"num"},[_c('span',[_vm._v("50,000+")])]),_c('div',{staticClass:"bottom"},[_c('span',[_vm._v("智能集装箱")])])]),_c('div',{staticClass:"salesVolume_Center"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/homePage/19.png"),"alt":""}})]),_c('div',{staticClass:"num"},[_c('span',[_vm._v("200+")])]),_c('div',{staticClass:"bottom"},[_c('span',[_vm._v("堆场")])])]),_c('div',{staticClass:"salesVolume_Right"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/homePage/21.png"),"alt":""}})]),_c('div',{staticClass:"num"},[_c('span',[_vm._v("1,000,000+")])]),_c('div',{staticClass:"bottom"},[_c('span',[_vm._v("交易额")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paas"},[_c('div',{staticClass:"pass_Left"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/homePage/116.png"),"alt":""}})]),_c('div',{staticClass:"center"},[_c('span',[_vm._v("一个平台，全球流通")])]),_c('div',{staticClass:"bottom"},[_c('span',[_vm._v("侨链国际贸易平台系统，24小时联通全球物流网络,提供实时数据与精准分析，智能化图形界面，让贸易操作更加灵活。")])])]),_c('div',{staticClass:"pass_Center"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/homePage/introResource.png"),"alt":""}})]),_c('div',{staticClass:"center"},[_c('span',[_vm._v("资源整合，快速周转")])]),_c('div',{staticClass:"bottom"},[_c('span',[_vm._v("整合进出口商、货运公司、航空/海运/公路运输公司、海关、港口等贸易各方 机构.加速箱舱快速流通，提高运输工具与集装箱的使用效率。")])])]),_c('div',{staticClass:"pass_Right"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/homePage/introSafe.png"),"alt":""}})]),_c('div',{staticClass:"center"},[_c('span',[_vm._v("跨境结算，安全快速")])]),_c('div',{staticClass:"bottom"},[_c('span',[_vm._v("侨链国际支持跨境结算业务，资金更快更安全。")])])]),_c('div',{staticClass:"pass_Right"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/homePage/ditan.png"),"alt":""}})]),_c('div',{staticClass:"center"},[_c('span',[_vm._v("绿色低碳，智能计算")])]),_c('div',{staticClass:"bottom"},[_c('span',[_vm._v("为不同碳减排场景创新开发碳计算模型")])])])])
}]

export { render, staticRenderFns }