<template>
    <div class="box">
        <Banner_Component></Banner_Component>
        <Server_Component></Server_Component>
        <AboutUs_Component></AboutUs_Component>
    </div>
</template>

<script>
import Banner_Component from './Banner_Component.vue'
import Server_Component from "./Server_Component.vue";
import AboutUs_Component from './AboutUs_Component..vue'
export default {
    name: "Content",
    components: { Banner_Component, Server_Component, AboutUs_Component },
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
.box {
    height: 100%;
}
</style>