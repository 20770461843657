
function getMoney(value) { // 价格的限制规则，只能输入小数点后两位
    console.log(value);
    value = value.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
    value = value.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
    value = value.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
    // value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')// 只能输入两个小数
    console.log("-=-=",value);
    // return value
}



export {
    getMoney
}