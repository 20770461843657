<template>
  <div id="app">

    <NavigaTionBar_Component></NavigaTionBar_Component>

    <!-- <router-view></router-view> -->
    <!-- <Login_Component></Login_Component> -->
  </div>
</template>

<script>
import NavigaTionBar_Component from './components/NavigaTionBar_Component.vue';
import Content_Component from "./components/Content_Component.vue";
import Footer_Compontent from "./components/Footer_Compontent.vue";
export default {
  name: "App",
  components: { NavigaTionBar_Component, Content_Component, Footer_Compontent, },
  data() {
    return {

    }
  },
  created() {
  
    let token = sessionStorage.getItem("token");
    if (token == null) {
      this.$router.push("/")
      console.log("%%%%");
    }
    if (localStorage.getItem("token")) {
      this.$store.commit("set_token", localStorage.getItem("token"));
      // console.log(this.$store.state.token);
    }
    if (localStorage.getItem("authInfo")) {
      this.$store.commit("set_authInfo", JSON.parse(localStorage.getItem("authInfo")));
    }

    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("token", this.$store.state.token);
      localStorage.setItem("authInfo", JSON.stringify(this.$store.state.authInfo));

      console.log("^^");
    })
    // console.log(this.$store.state);
    // //在页面加载时读取localStorage里的状态信息
    // localStorage.getItem("token") && this.$store.replaceState(Object.assign(this.$store.state,JSON.parse(localStorage.getItem("token"))));
  },
  mounted() {

  },
  methods: {},
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  /* -webkit-text-size-adjust: none; */
  box-sizing: border-box;
  /* font-family: 'Alibaba PuHuiTi'; */
}

body {

  background: #FFFFFF;
  /* background-color: #f0f2f5; */
}

/* html,
body,
#app {
  min-height: 100%;
} */
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; */
}
</style>